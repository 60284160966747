var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-cintillos-free",attrs:{"title":"Cintillos disponibles","cancel-title":"Cancelar","scrollable":"","size":"lg","title-tag":"h3"},on:{"hidden":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("cerrar")])]},proxy:true}])},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{ref:"table-list",staticClass:"mb-0",attrs:{"items":_vm.myProvider,"fields":_vm.fields,"sticky-header":"40vh","primary-key":"id","responsive":"","small":"","show-empty":"","striped":"","table-class":"text-nowrap"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Cargando...")])],1)]},proxy:true},{key:"cell(cintillo)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('CombChickenlegsCintillos',{attrs:{"postura":{
										chickenleg_wich_leg: data.item.wich_leg,
										chickenleg_qty_cintillos: data.item.qty,
										cintillo_color: data.item.hex_1,
										cintillo_description: data.item.color_1,
										second_color_color: data.item.hex_2,
										second_color_description: data.item.color_2,
										total_minus_infertile_eggs: 1,
									},"chickenleg_wich_leg":1,"is_in_posturas":""}}),_c('CombChickenlegsCintillos',{attrs:{"postura":{
										chickenleg_wich_leg: data.item.wich_leg,
										chickenleg_qty_cintillos: data.item.qty,
										cintillo_color: data.item.hex_1,
										cintillo_description: data.item.color_1,
										second_color_color: data.item.hex_2,
										second_color_description: data.item.color_2,
										total_minus_infertile_eggs: 1,
									},"chickenleg_wich_leg":2,"is_in_posturas":""}})],1)]}},{key:"cell(priority)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(data.item.priority))])])]}},{key:"cell(created_by)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('span',[_vm._v(_vm._s(data.item.created_by))]),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDayWithHour")(data.item.created_at)))])])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }