import axios from "@/axios";

const url = "/api/releases/";

class ReleasesService {
	async getCounter() {
		try {
			const { data } = await axios.get(`${url}counter-posturas-to-release-cintillos`);
			return { ok: true, data };
		} catch (err) {
			console.error(err);
			return { ok: false, error: err.response.data.message };
		}
	}

	async getPosturas() {
		try {
			const { data } = await axios.get(`${url}posturas`);
			return { ok: true, data };
		} catch (err) {
			console.error(err);
			return { ok: false, error: err.response.data.message };
		}
	}

	async releaseHeadband(cpp_id) {
		try {
			const { data } = await axios.put(`${url}release-postura-headband/${cpp_id}`);
			return { ok: true, message: data.message };
		} catch (err) {
			console.error(err);
			return { ok: false, error: err.response.data.message };
		}
	}

	async getCintillosFree(){
		try {
			const data = await axios.get(`/api/cintillos/free`);
			return data;
		} catch (error) {
			throw error
		}
	}
}
export default new ReleasesService();
