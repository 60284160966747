<template>
	<div>
		<b-modal
			ref="modal-cintillos-free"
			:title="`Cintillos disponibles`"
			@hidden="$emit('close')"
			cancel-title="Cancelar"
			scrollable
			size="lg"
			title-tag="h3"
		>
			<b-card no-body class="mb-0">
				<div class="table-responsive mb-0">
					<b-table
						:items="myProvider"
						:fields="fields"
						sticky-header="40vh"
						primary-key="id"
						responsive
						small
						show-empty
						ref="table-list"
						striped
						table-class="text-nowrap"
						class="mb-0"
					>
						<template #table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Cargando...</strong>
							</div>
						</template>
						<template #cell(cintillo)="data">
							<div class="d-flex justify-content-center">
								<CombChickenlegsCintillos
									:postura="{
										chickenleg_wich_leg: data.item.wich_leg,
										chickenleg_qty_cintillos: data.item.qty,
										cintillo_color: data.item.hex_1,
										cintillo_description: data.item.color_1,
										second_color_color: data.item.hex_2,
										second_color_description: data.item.color_2,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="1"
									is_in_posturas
								/>
                                <CombChickenlegsCintillos
									:postura="{
										chickenleg_wich_leg: data.item.wich_leg,
										chickenleg_qty_cintillos: data.item.qty,
										cintillo_color: data.item.hex_1,
										cintillo_description: data.item.color_1,
										second_color_color: data.item.hex_2,
										second_color_description: data.item.color_2,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="2"
									is_in_posturas
								/>
							</div>
						</template>
						<template #cell(priority)="data">
							<div class="d-flex justify-content-center">
								<span class="text-primary font-weight-bold">{{ data.item.priority }}</span>
							</div>
						</template>
						<template #cell(created_by)="data">
							<div class="d-flex flex-column justify-content-center align-items-center">
								<span>{{ data.item.created_by }}</span>
								<span>{{ data.item.created_at | myGlobalDayWithHour }}</span>
							</div>
						</template>
					</b-table>
				</div>
			</b-card>

			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">cerrar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import ReleasesService from "@/services/releases.service"
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos.vue"
export default {
	props: {},
	components: { CombChickenlegsCintillos },
	data() {
		return {
			fields: [
				{
					key: "cintillo",
					label: "Combinacion",
					thClass: "text-center",
					tdClass: "text-center",
					visible: true,
				},
				{
					key: "priority",
					label: "Prioridad",
					thClass: "text-center",
					tdClass: "text-center",
					visible: true,
				},
				{
					key: "qty",
					label: "Qty Cintillos",
					thClass: "text-center",
					tdClass: "text-center",
					visible: true,
				},
				{
					key: "posicion",
					label: "Posicion",
					thClass: "text-center",
					tdClass: "text-center",
					visible: true,
				},
				{
					key: "status",
					label: "Estado",
					thClass: "text-center",
					tdClass: "text-center",
					visible: true,
				},
			],
			form: {
				name: null,
			},
		}
	},
	directives: { Ripple },
	mixins: [modalMixin],

	async mounted() {
		this.toggleModal("modal-cintillos-free")
	},

	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			try {
				// const { data } = await wareService.getTrackingOutputs(this.info.product_input_id)
				const { data } = await ReleasesService.getCintillosFree()
				console.log("data: ", data)
				return data
			} catch (error) {
				console.log("error: ", error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		closeModal() {
			this.$emit("close")
		},
		resetList() {
			this.$refs["table-list"].refresh()
		},
	},
}
</script>
<style scoped></style>
