<template>
	<div class="d-flex">
		<b-button
			variant="outline-primary"
			size="sm"
			class="mr-2 d-flex align-items-center justify-content-center btn-icon"
			v-b-tooltip.hover
			:title="`CINTILLOS LIBRES`"
			@click="showCintilloFreeModal = true"
		>
			<div :style="`background-color: red;`" class="cintillo">
				<!-- <span v-if="is_in_posturas">
						{{ postura.chickenleg_wich_leg == 1 || postura.chickenleg_wich_leg == 3 ? "I" : "D" }}
					</span> -->
			</div>
		</b-button>

		<b-button block variant="outline-primary" @click="openModalReleaseCintillos">
			LIBERAR CINTILLOS ({{ counterPCP }})
		</b-button>

		<b-modal
			hide-footer
			scrollable
			v-model="modalReleaseCintillos.show"
			:title="modalReleaseCintillos.title"
			heigth="100vh"
			size="xl"
		>
			<div>
				<b-row class="p-0">
					<b-col cols="12" md="6">
						<h3>Por liberar: {{ posturas.length }}</h3>
					</b-col>
					<b-col cols="12" md="6" class="mb-1">
						<b-form-input v-model="search" placeholder="Buscar placa o alias / Postura"></b-form-input>
					</b-col>
				</b-row>
				<b-table-simple sticky-header="calc(100vh - 190px)">
					<b-thead>
						<b-tr class="text-center">
							<b-th>POSTURA</b-th>
							<b-th>MADRILLA</b-th>
							<b-th>PADRILLO</b-th>
							<b-th>PATA IZQ</b-th>
							<b-th>PATA DER</b-th>
							<b-th>LIBERAR</b-th>
						</b-tr>
					</b-thead>

					<b-tbody>
						<template v-if="posturasPaginated.length > 0">
							<b-tr
								class="text-center"
								v-for="(item, index) in posturasPaginated"
								:key="`${index}-posturas`"
							>
								<b-td>
									<p
										@click="openModalPosturaView(item)"
										class="mb-0 text-primary font-weight-bold cursor-pointer"
									>
										{{ item.p_code }}
									</p>
								</b-td>
								<b-td class="text-center">
									<SpecimenPlate
										:specimen="{
											plate: item.madrilla.plate,
											alias: item.madrilla.alias,
											id: item.madrilla.id,
											thumb: item.madrilla.thumb,
											image: item.madrilla.image,
										}"
									/>
								</b-td>
								<b-td class="text-center">
									<SpecimenPlate
										:specimen="{
											plate: item.padrillo.plate,
											alias: item.padrillo.alias,
											id: item.padrillo.id,
											thumb: item.padrillo.thumb,
											image: item.padrillo.image,
										}"
									/>
								</b-td>
								<b-td>
									<CombChickenlegsCintillos
										v-if="item.cintillos"
										:postura="{
											id: item.p_id,
											chickenleg_wich_leg: item.cintillos.wich_leg,
											chickenleg_qty_cintillos: item.cintillos.qty_cintillos,
											cintillo_color: item.cintillos.hex_1,
											cintillo_description: item.cintillos.description_1,
											second_color_color: item.cintillos.hex_2,
											second_color_description: item.cintillos.description_2,
											total_minus_infertile_eggs: 1,
										}"
										:chickenleg_wich_leg="1"
									/>
								</b-td>
								<b-td>
									<CombChickenlegsCintillos
										v-if="item.cintillos"
										:postura="{
											id: item.p_id,
											chickenleg_wich_leg: item.cintillos.wich_leg,
											chickenleg_qty_cintillos: item.cintillos.qty_cintillos,
											cintillo_color: item.cintillos.hex_1,
											cintillo_description: item.cintillos.description_1,
											second_color_color: item.cintillos.hex_2,
											second_color_description: item.cintillos.description_2,
											total_minus_infertile_eggs: 1,
										}"
										:chickenleg_wich_leg="2"
									/>
								</b-td>

								<b-td>
									<FeatherIcon
										@click="releaseHeadband(item.cintillos.cpp_id, item.p_code)"
										icon="CheckCircleIcon"
										size="20"
										class="text-success cursor-pointer"
									/>
								</b-td>
							</b-tr>
						</template>
						<template v-else>
							<b-tr>
								<b-td colspan="6">
									<h5 class="text-center p-1 w-100">No se encontraron registros</h5>
								</b-td>
							</b-tr>
						</template>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-modal>

		<b-modal
			v-model="modalViewByPostura.visible"
			:title="modalViewByPostura.title"
			size="xmd"
			hide-footer
			body-class="m-0 p-2"
		>
			<LotesList :postura_id="modalViewByPostura.postura_id" />
		</b-modal>

		<CintillosFreeModal v-if="showCintilloFreeModal" @close="showCintilloFreeModal = false" />
	</div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import CintillosFreeModal from "@/views/amg/encaste/lotes/components/CintillosFree.vue"
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos"
import LotesList from "@/views/amg/encaste/lotes/LotesList.vue"
import releasesService from "@/services/releases.service"
export default {
	name: "RealeaseCintillos",
	components: {
		SpecimenPlate,
		LotesList,
		CombChickenlegsCintillos,
		CintillosFreeModal,
	},
	async mounted() {
		await this.getCounterPosturasToReleaseCintillos()
	},
	computed: {
		posturasPaginated() {
			const options = {
				keys: ["p_code", "padrillo.alias", "padrillo.plate", "madrilla.alias", "madrilla.plate"],
			}
			const fuse = new Fuse(this.posturas, options)
			return this.search.length > 0 ? fuse.search(this.search).map(({ item }) => item) : this.posturas
		},
	},
	data() {
		return {
			modalReleaseCintillos: {
				show: false,
				title: "Liberar Cintillos",
			},
			start_page: 1,
			to_page: 1,
			total_data: 1,
			search: "",
			current_page: 1,
			perpage: 1,
			posturas: [],
			modalViewByPostura: {
				title: "Detalle de postura",
				visible: false,
				postura_id: null,
			},
			counterPCP: 0,
			showCintilloFreeModal: false,
		}
	},
	methods: {
		async getCounterPosturasToReleaseCintillos() {
			const { ok, data } = await releasesService.getCounter()
			if (ok) this.counterPCP = data.counter
		},
		async getPosturasFinished() {
			this.isPreloading()
			const { data } = await releasesService.getPosturas()
			this.posturas = data.posturas
			this.isPreloading(false)
		},
		async releaseHeadband(cpp_id, p_code) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			const { ok, message } = await releasesService.releaseHeadband(cpp_id)
			if (ok) {
				this.showSuccessToast(message + ` ${p_code}`)
				this.getPosturasFinished()
				this.counterPCP--
			}
		},
		openModalPosturaView(data) {
			this.modalViewByPostura.visible = true
			this.modalViewByPostura.title = `${data.p_code}`
			this.modalViewByPostura.postura_id = data.id
		},
		openModalReleaseCintillos() {
			this.modalReleaseCintillos.show = true
			this.getPosturasFinished()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-cintillos {
	margin-top: 0.5rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;

	.cintillo {
		height: 30px;
		width: 20px;
		background: red;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 64%, 0 100%);
		position: relative;
		margin-right: 0.5rem;

		:last-child {
			margin-right: 0;
		}

		span {
			font-weight: bold;
			position: absolute;
			left: 50%;
			translate: -50%;
			color: #fff;
		}
	}
}
</style>
