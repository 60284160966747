<template>
	<div>
		<b-container fluid class="container-evidences-deads text-center">
			<b-row v-if="evidences.length > 0">
				<b-col v-for="(evidence, index) in evidences" :key="index">
					<img thumbnail fluid width="" class="ml-1 mb-1 clickable img-evidence" :src="evidence.url" />
				</b-col>
			</b-row>
			<div v-else>
				<p class="mb-0 font-weight-bolder">Sin evidencias del ejemplar</p>

			</div>
		</b-container>
	</div>
</template>

<script>
export default {
	name: "DeadSpecimenEvidence",
	props: {
		deadSpecimenId: {
			type: Number,
			required: true,
		},
	},
	mounted() {
		this.getDeadSpecimenEvidence();
	},
	data() {
		return {
			evidences: [],
		};
	},
	methods: {
		getDeadSpecimenEvidence() {
			axios
				.post("/api/deads/evidence", {
					dead_specimen_id: this.deadSpecimenId,
				})
				.then((res) => {
					this.evidences = res.data;
				});
		},
	},
};
</script>

<style lang="scss">
.container-evidences-deads {
	// padding: 1rem;
	@media screen and (min-width: 600px) {
		padding: 4rem;
	}
}
.img-evidence {
	width: 120px;
	height: 120px;
	object-fit: cover;
	object-position: center;
	border: 1px solid #ccc;
	padding: 0.2rem;
	border-radius: 4px;
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	@media screen and (min-width: 600px) {
		width: 200px;
		height: 200px;
	}
}
</style>
