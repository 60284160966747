<template>
	<div>

		<b-card no-body class="mb-1">
			<b-row class="px-2 pt-2 pb-1">
				<b-col cols="12" lg="2" md="2">
					<b-form-group>
						<v-select
							v-model="year"
							label="season"
							:options="years"
							:reduce="opt=>opt.id"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:clearable="false"
						/>
					</b-form-group>
				</b-col>	
				<b-col cols="12" lg="2" md="3" class="mb-1">
					<!-- <b-button block variant="primary" @click="controllerDeadChicks = true">
						<feather-icon icon="FeatherIcon" />
						MUERTOS
					</b-button> -->
				</b-col>
				<b-col cols="12" offset-lg="3" lg="3" md="4" class="mb-1 d-flex justify-content-end">
				<div style="max-width: 350px;">
					<ReleaseCintillos />
				</div>
				</b-col>
				<b-col cols="12" lg="2" md="3" class="mb-1">
					<b-button block variant="primary" @click="openModalCalendar = !openModalCalendar">
						<feather-icon icon="CalendarIcon" />
						CALENDARIO
					</b-button>
				</b-col>
				
				
			</b-row>
			

			<CombinationList v-if="controllerCombinations" @hidden="closeCombinations" />

			<LotesList :year="year"/>
			
		</b-card>

		<b-container fluid class="mt-2">
			<b-row>
				<b-col cols="12" md="6" class="mb-2">
					<h4 class="mb-1">LEYENDA</h4>

					<div class="d-flex flex-wrap">
						<div class="item-lotes-legend" v-for="(item, key) in typesLotes" :key="key">
							<img :src="item.img" alt="" />
							<p>
								<span>{{ item.code }}</span
								>: {{ item.name }}
							</p>
						</div>
					</div>
				</b-col>

				<b-col cols="12" md="2" lg="3"></b-col>

				<b-col cols="12" md="4" lg="3">
					<h4 class="mb-1">ETAPAS:</h4>
					<div class="mb-3">
						<ul class="p-0">
							<li v-for="(stage, index) in arrayStages" :key="`stage-${index}`">
								{{ index + 1 }}: {{ stage }}
							</li>
						</ul>
					</div>

					<div class="mb-2">
						<ul class="p-0">
							<li class="d-flex align-items-center mb-1">
								<h3 class="mb-0" style="width: 180px">INACTIVA</h3>
								<div class="circle-leyend rounded-circle bg-secondary" style="opacity: 0.6"></div>
							</li>
							<li class="d-flex align-items-center mb-1">
								<h3 class="mb-0" style="width: 180px">PENDIENTE</h3>
								<div class="circle-leyend rounded-circle bg-danger"></div>
							</li>
							<li class="d-flex align-items-center mb-1">
								<h3 class="mb-0" style="width: 180px">REALIZADA</h3>
								<div class="circle-leyend rounded-circle bg-success"></div>
							</li>
						</ul>
					</div>
				</b-col>
			</b-row>
		</b-container>

		<!-- Deads CP -->
		<!-- <DeadChicks :tabIndex="0" v-if="controllerDeadChicks" @hidden="closeModalDeadChicks" /> -->

		<DeadsModal :show="controllerDeadChicks" @closing="closeModalDeadChicks" />

		<!-- Modal Calendar -->
		<b-modal
			v-model="openModalCalendar"
			title="Calendario de lotes"
			size="xl"
			hide-footer
			scrollable
			body-class="m-0 p-0"
		>
			<CalendarPosturasView v-if="openModalCalendar" />
		</b-modal>
	</div>
</template>
<script>
import { bus } from "@/main";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import CombinationList from "@/views/amg/snoods/combinations/CombinationList.vue";
import DeadChicks from "@/views/amg/encaste/chickens/modals/DeadChicks.vue";
import CalendarPosturasView from "../posturas/CalendarPosturasView.vue";
import ReleaseCintillos from "./components/ReleaseCintillos.vue";
import LotesList from "./LotesList.vue";
import DeadsModal from '../../deads/v2/DeadsModal.vue';

export default {
	name: "LotesMain",
	directives: {
		Ripple,
	},
	components: {
		vSelect,
		AppCollapse,
		AppCollapseItem,
		CombinationList,
		DeadChicks,
		CalendarPosturasView,
		ReleaseCintillos,
		LotesList,
		DeadsModal
	},
	mounted() {
		// this.years = this.generateYears();
	},
	data() {
		return {
			show: false,
			controllerCombinations: false,
			year: null,
			controllerDeadChicks: false,
			openModalCalendar: false,
			typesLotes: [
				{
					img: "/lotes/lote-grid-1.svg",
					code: "POS",
					name: "POSTURAS",
					key: "sum_posturas",
				},
				{
					img: "/lotes/lote-grid-2.svg",
					code: "QUE",
					name: "QUEMADOS",
					key: "sum_burned",
				},
				{
					img: "/lotes/lote-grid-3.svg",
					code: "NAC",
					name: "NACIERON",
					key: "sum_born",
				},
				{
					img: "/lotes/lote-grid-4.svg",
					code: "MUE",
					name: "MUERTOS",
					key: "sum_dead",
				},
				{
					img: "/lotes/lote-grid-5.svg",
					code: "ING",
					name: "INGRESADOS",
					key: "sum_eggs",
				},
				{
					img: "/lotes/lote-grid-6.svg",
					code: "INC",
					name: "INCUBANDO",
					key: "sum_brooder",
				},
				{
					img: "/lotes/lote-grid-7.svg",
					code: "NO N",
					name: "NO NACIERON",
					key: "sum_no_born",
				},
				{
					img: "/lotes/lote-grid-8.svg",
					code: "EFE",
					name: "EFECTIVIDAD",
					key: "efectivity",
				},
				{
					img: "/lotes/lote-grid-9.svg",
					code: "INF",
					name: "INFERTILES",
					key: "sum_infer",
				},
				{
					img: "/lotes/lote-grid-10.svg",
					code: "FER",
					name: "FERTILIDAD",
					key: "fertility",
				},
				{
					img: "/lotes/lote-grid-10.svg",
					code: "VIV",
					name: "VIVOS",
					key: "sum_alive",
				},
			],
			arrayStages: [
				"CREACIÓN DEL LOTE",
				"REVISIÓN INFÉRTILES/QUEMADOS",
				"REVISIÓN INFÉRTILES/QUEMADOS",
				"REVISIÓN DE NACIDOS/CINTILLO PATA",
				"VACUNACION: VIRUELA + TRIPLE AVIAR",
				"INGRESO DE CINTILLO ALA",
			],
		};
	},
	computed:{
		years() {
			this.year = this.seasons[0].id;
			return this.$store.state.utilities.seasons;
		},
		ammendedYear() {
			return this.seasonFromId(this.year).season;
		},
	},
	methods: {
		moment() {
			return moment();
		},
		openCombinations() {
			this.controllerCombinations = true;
		},
		closeCombinations() {
			this.controllerCombinations = false;
		},
		generateYears() {
			let years = [];
			for (let i = -2; i < 1; i++) {
				let obj = {
					year: moment().add(`${i}`, "Y").format("YYYY"),
					label: moment().add(`${i}`, "Y").format("YYYY"),
				};
				years.push(obj);
			}
			return years.sort().reverse();
		},
		closeModalDeadChicks() {
			this.controllerDeadChicks = false;
			console.log('Cerrar modal');
			bus.$emit("reload-lotes-list");
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}

.circle-leyend {
	width: 30px;
	height: 30px;
}

.item-lotes-legend {
	background: #f5f5f5;
	// border-radius: 100%;
	border: #7367f0 solid 1px;
	display: flex;
	padding: 0.4rem 1rem;
	margin-bottom: 1rem;
	border-radius: 20px;
	margin-right: 0.5rem;
	img {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
	p {
		margin-left: 0.5rem;
		// background: red;
		margin-bottom: 0;
		// width: 200px;
		min-width: 150px;
		display: flex;
		align-items: center;
		width: auto;
		font-size: 14px;
		span {
			font-weight: bolder;

			font-size: 14px;
		}
	}
}

.box-primary {
	background: #7367f0 !important;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	overflow: hidden;
	margin: 2rem 0 !important;
	height: 40px;
	// display: flex;
	// align-items: center;
	padding-top: 0.7rem !important;
}
.container-head {
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		height: 14px;
		width: 14px;
		object-fit: contain;
	}
	p {
		margin-bottom: 0;
		margin-left: 0.2rem;
	}
}

li {
	list-style: none;
}
</style>
