<template>
  <b-modal
    ref="modal-dead-chicks"
    @hidden="$emit('hidden')"
    title="Muertos"
    no-close-on-backdrop
    ok-only
    ok-title="Cerrar"
    ok-variant="secondary"
    size="xl"
  >
    <b-tabs v-model="tabEachIndex">
      <b-tab title="Cintillo Pata">
        <DeadCintilloPataGrid v-if="tabEachIndex == 0" />
      </b-tab>
      <b-tab title="Cintillo Ala">
        <DeadCintilloAlaGrid v-if="tabEachIndex == 1" />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import DeadCintilloPataGrid from "@/views/amg/deads/DeadCintilloPataGrid.vue";
import DeadCintilloAlaGrid from "@/views/amg/deads/DeadCintilloAlaGrid.vue";
export default {
  name: "DeadChicks",
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DeadCintilloPataGrid,
    DeadCintilloAlaGrid,
  },
  mixins: [modalMixin],
  mounted() {
    this.toggleModal("modal-dead-chicks");
  },
  data() {
    return {
      tabEachIndex: this.tabIndex,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
