<template>
	<div>
		<!-- {{registerDeadsController}} -->
		<b-card no-body class="mb-1">
			<div class="m-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted" v-if="total_data">
								Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
							</span>
							<span v-else> Sin registros </span>
						</b-col>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
				<b-row>
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<!-- Search -->
							<div class="w-100 d-flex align-items-center justify-content-lg-end mr-lg-2">
								<h4 class="mb-0 mr-1" style="min-width: 9ch">Buscar por:</h4>
								<div>
									<b-form-checkbox v-model="statusSearch" value="specimen" unchecked-value="code">
										Padrillo/Madrilla/Postura
									</b-form-checkbox>
									<b-form-checkbox v-model="statusSearch" value="code" unchecked-value="specimen">
										Cintillo
									</b-form-checkbox>
								</div>
							</div>
							<b-form-input
								v-model.trim="search_input"
								class="d-inline-block mr-1"
								:placeholder="statusSearch == 'specimen' ?  'Placa, Alias (Madrilla y Padrillo) y Postura' : 'Código (Cintillo)' "
								debounce="500"
							/>
							<b-button @click="openRegisterDeads" v-if="isAdmin || isEncastador" variant="primary">
								<span class="text-nowrap">Registrar muerto</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="table-responsive">
				<b-table
					:api-url="'/api/deads/get-grouped-ca'"
					ref="refDeadList"
					class="position-relative"
					:items="myProvider"
					:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
					sticky-header="75vh"
					:fields="arrayColumns"
					primary-key="id"
					table-class="text-nowrap"
					responsive
					show-empty
					:busy.sync="isBusy"
					empty-filtered-text="No hay registros que coincidan con tu búsqueda."
					empty-text="No hay registros que mostrar."
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:current-page="current_page"
					:per-page="perpage"
					:filter="search_input"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(postura)="data">
						<a href="#" class="font-weight-bolder" @click="openModalPosturaDetail(data.item)">{{
							data.item.code
						}}</a>
					</template>
					<template #cell(plate_s2)="{ item }">
						<SpecimenPlate
							:specimen="{
								id: item.madrilla.id,
								plate: item.madrilla.plate,
								alias: item.madrilla.alias,
								thumb: item.madrilla.thumb,
							}"
						/>
					</template>
					<template #cell(alias_s1)="{ item }">
						<SpecimenPlate
							:specimen="{
								id: item.padrillo.id,
								plate: item.padrillo.plate,
								alias: item.padrillo.alias,
								thumb: item.padrillo.thumb,
							}"
						/>
					</template>
					<template #cell(suma)="{ item }">
						{{ item.quantity }}
					</template>
					<template #cell(lote)="data">
						{{ data.item.lote_code }}
					</template>
					<template #cell(created_at)="data">
						{{ data.item.created_at | myGlobalDayWhour }}
					</template>
					<template #cell(actions)="data">
						<!-- <feather-icon
              icon="ClipboardIcon"
              class="cursor-pointer mr-1 text-info"
              size="20"
              @click="openModalEvidences(data.item)"
              :id="`evidences-tooltip${data.index}`"
            />
            <b-tooltip
              :target="`evidences-tooltip${data.index}`"
              placement="top"
            >
              Evidencias
            </b-tooltip>
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="20"
              @click="deleteDeadSpecimen(data.item)"
              :id="`delete-tooltip${data.index}`"
            />
            <b-tooltip :target="`delete-tooltip${data.index}`" placement="top">
              Eliminar
            </b-tooltip> -->
						<feather-icon
							icon="EyeIcon"
							class="cursor-pointer mr-1 text-info"
							size="20"
							@click="openModalDetail(data.item)"
							:id="`evidences-tooltip${data.index}`"
						/>
						<b-tooltip :target="`evidences-tooltip${data.index}`" placement="top"> Detalles </b-tooltip>
					</template>
				</b-table>
			</div>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted"
							>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
						>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- REGISTRAR MUERTOS -->
		<RegisterDeads v-if="registerDeadsController" @hidden="closeRegisterDeads" :evolution-specimen="2" />

		<b-modal v-model="deadSpecimenDetail.show" size="xl" :title="deadSpecimenDetail.title" hide-footer>
			<dead-cintillo-ala-detail
				@refreshTableZero="refreshTableZero"
				:posturaId="selectedPosturaId"
			></dead-cintillo-ala-detail>
		</b-modal>

		<b-modal
			id="detailPosturaModal"
			v-model="modalDetailPostura.show"
			:title="modalDetailPostura.title"
			hide-footer
			scrollable
			size="xmd"
		>
			<PosturasListViewById :postura_id="selectedPosturaId" />
		</b-modal>
	</div>
</template>
<script>
import axios from "@/axios";
import Ripple from "vue-ripple-directive";
import RegisterDeads from "./modals/RegisterDeads.vue";
import DeadSpecimenEvidence from "./modals/DeadSpecimenEvidence";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PosturaDetail from "../encaste/posturas/PosturaDetail.vue";
import DeadCintilloAlaDetail from "./DeadCintilloAlaDetail.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import PosturasListViewById from "@/views/amg/encaste/posturas/PosturasListViewById.vue";
export default {
	directives: {
		Ripple,
	},
	components: {
		RegisterDeads,
		DeadSpecimenEvidence,
		PosturaDetail,
		DeadCintilloAlaDetail,
		SpecimenPlate,
		PosturasListViewById,
	},
	watch: {
		deadSpecimenDetail(newValue, oldValue) {
			if (newValue.show == false) {
				this.deadSpecimenDetail.title = "";
			}
		},
		modalDetailPostura(newValue, oldValue) {
			if (newValue.show == false) {
				this.modalDetailPostura.title = "";
			}
		},
	},
	data() {
		return {
			sortBy: "plate_s2",
			sortDesc: false,
			arrayColumns: [
				{
					key: "plate_s2",
					label: "Madrilla",
					class: "text-center",
					sortable: true,
				},
				{
					key: "alias_s1",
					label: "Padrillo",
					class: "text-center",
					sortable: true,
				},
				{
					key: "code",
					label: "Postura",
					class: "text-center",
					sortable: false
				},
				{
					key: "quantity",
					label: "Cantidad",
					class: "text-center",
					sortable: true,
				},
				{ key: "actions", label: "Acciones", class: "text-center " },
			],
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			perPageOptions: [10, 25, 50, 100],
			chickenleg: null,
			loading: false,
			createDeadsPageController: false,
			deadSpecimenDetail: {
				show: false,
				title: "",
			},
			selectedDeadSpecimenId: null,
			show: false,
			modalDetailPostura: {
				show: false,
				title: "",
			},
			selectedPosturaId: null,
			registerDeadsController: false,
			statusSearch: "specimen",
		};
	},
	methods: {
		openModalPosturaDetail(postura) {
			console.log({ postura });
			this.selectedPosturaId = postura.id;
			this.modalDetailPostura = {
				show: true,
				title: postura.code,
			};
		},
		onChangeFilter() {
			this.$refs.refDeadList.refresh();
		},
		myProvider(ctx) {
			const promise = axios.post(`${ctx.apiUrl}?page=${ctx.currentPage}`, {
				orderby: ctx.sortDesc == 0 ? "asc" : "desc",
				order: ctx.sortBy == "" ? 'plate_s2' : ctx.sortBy,
				campo: ctx.filter,
				perpage: ctx.perPage,
				type_specimen: 2,
				type_search: this.statusSearch == "specimen" ? 1 : 2,
			});
			return promise.then(({ data }) => {
				const items = data.data;
				this.start_page = data.from;
				this.current_page = data.current_page;
				this.perpage = data.per_page;
				this.next_page = this.start_page + 1;
				this.end_page = data.last_page;
				this.total_data = data.total;
				this.to_page = data.to;
				return items || [];
			});
		},
		resetSearch() {
			this.search_input = "";
			this.$refs.refDeadList.refresh();
		},
		openRegisterDeads() {
			this.registerDeadsController = true;
		},
		closeRegisterDeads() {
			this.registerDeadsController = false;
			this.resetSearch();
		},
		openModalDetail(dead) {
			this.selectedPosturaId = dead.id;
			this.deadSpecimenDetail = {
				show: true,
				title: `Muertos de ${dead.code} en cintillo en ala.`,
			};
		},
		openModalEvidences(dead) {
			this.selectedDeadSpecimenId = dead.id;
			this.deadSpecimenDetail = {
				show: true,
				title: dead.code,
			};
		},
		showToast(variant, position, title, icon, text) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			);
		},
		deleteDeadSpecimen(dead) {
			this.$swal({
				title: "Estas seguro?",
				text: "¡No podrás revertir esto!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Confirmar",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios
						.post("/api/deads/delete", {
							deleted_by: this.currentUser.user_id,
							dead_specimen_id: dead.id,
							cintillo_pata: dead.cintillo_pata,
							cintillo_ala: dead.cintillo_ala,
							quantity: dead.quantity,
							plate: dead.plate,
							type_specimen: dead.type_specimen_number,
							postura_id: dead.postura_id,
							lote_id: dead.lote_id,
						})
						.then((res) => {
							this.showToast("success", "top-right", "Felicidades", "CheckIcon", "Muerte eliminada!");
							this.$refs.refDeadList.refresh();
						});
				}
			});
		},
		refreshTableZero() {
			this.$refs.refDeadList.refresh();
			// this.deadSpecimenDetail = false;
			this.deadSpecimenDetail.show = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
</style>
