<template>
	<div>
		<b-card no-body class="mb-1">
			<div class="m-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted"
								>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
							>
						</b-col>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
				<b-row>
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<!-- Search -->
							<b-form-input
								v-model.trim="search_input"
								class="d-inline-block mr-1"
								placeholder="Placa Madrilla, Padrillo, Alias y Postura"
								debounce="500"
							/>
							<b-button @click="openRegisterDeads" v-if="isAdmin || isEncastador" variant="primary">
								<span class="text-nowrap">Registrar muerto</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="table-responsive">
				<b-table
					:api-url="'/api/deads/get-grouped-cp'"
					ref="refDeadList"
					class="position-relative"
					:items="myProvider"
					:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
					sticky-header="75vh"
					:fields="arrayColumns"
					primary-key="id"
					table-class="text-nowrap"
					responsive
					show-empty
					:busy.sync="isBusy"
					empty-filtered-text="No hay registros que coincidan con tu búsqueda."
					empty-text="No hay registros que mostrar."
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:current-page="current_page"
					:per-page="perpage"
					:filter="search_input"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>

					<!-- <template #cell(postura)="{ item }">
						sss
					</template> -->

					<template #cell(sm.plate)="{ item }">
						<SpecimenPlate
							:specimen="{
								id: item.madrilla.id,
								plate: item.madrilla.plate,
								alias: item.madrilla.alias,
								thumb: item.madrilla.thumb,
								image: item.madrilla.image,
							}"
						/>
					</template>
					<template #cell(sp.alias)="{ item }">
						<SpecimenPlate
							:specimen="{
								id: item.padrillo.id,
								plate: item.padrillo.plate,
								alias: item.padrillo.alias,
								thumb: item.padrillo.thumb,
								image: item.padrillo.image,
							}"
						/>
					</template>
					<!-- <template #cell(description)="data">
						<div>
							<div class="mr-auto p-1">
								<span class="fa-squaress">
									<feather-icon
										:style="`color: ${data.item.cintillo_color}; background: ${data.item.cintillo_color}; border-radius: 3px;`"
										icon="SquareIcon"
										size="25"
									/>
									<feather-icon
										v-if="data.item.second_color_color"
										:style="`color: ${data.item.second_color_color}; background: ${data.item.second_color_color}; border-radius: 3px;`"
										icon="SquareIcon"
										size="25"
									/>
								</span>
							</div>
							<div class="p-1">
								<span
									>{{ data.item.chickenleg_description }}
									{{ data.item.cintillo_description }}
									{{ data.item.second_color_description }}</span
								>
							</div>
						</div>
					</template> -->
					<template #cell(postura)="data">
						<div class="d-flex align-items-center justify-content-center mb-50">
							<CombChickenlegsCintillos
								v-if="data.item.cintillos && data.item.cintillos.id"
								:postura="{
									chickenleg_wich_leg: data.item.cintillos.chickenleg_wich_leg,
									chickenleg_qty_cintillos: data.item.cintillos.qty_cintillos,
									cintillo_color: data.item.cintillos.hex_1,
									cintillo_description: data.item.cintillos.description_1,
									second_color_color: data.item.cintillos.hex_2,
									second_color_description: data.item.cintillos.description_2,
									total_minus_infertile_eggs: 1,
								}"
								:chickenleg_wich_leg="1"
								is_in_posturas
							/>
							<CombChickenlegsCintillos
								v-if="data.item.cintillos && data.item.cintillos.id"
								:postura="{
									chickenleg_wich_leg: data.item.cintillos.chickenleg_wich_leg,
									chickenleg_qty_cintillos: data.item.cintillos.qty_cintillos,
									cintillo_color: data.item.cintillos.hex_1,
									cintillo_description: data.item.cintillos.description_1,
									second_color_color: data.item.cintillos.hex_2,
									second_color_description: data.item.cintillos.description_2,
									total_minus_infertile_eggs: 1,
								}"
								is_in_posturas
								:chickenleg_wich_leg="2"
							/>
						</div>
						<a href="#" class="font-weight-bolder" @click="openModal(data.item)">{{ data.item.code }}</a>
					</template>
					<template #cell(lote)="data">
						{{ data.item.lote_code }}
					</template>
					<template #cell(created_at)="data">
						{{ data.item.created_at | myGlobalDayWhour }}
					</template>
					<template #cell(actions)="data">
						<feather-icon
							icon="EyeIcon"
							class="cursor-pointer mr-1 text-info"
							size="20"
							@click="openModalDetail(data)"
							v-b-tooltip.hover
							title="Detalles"
						/>
					</template>
				</b-table>
			</div>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted"
							>Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros</span
						>
					</b-col>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- REGISTRAR MUERTOS -->
		<RegisterDeads v-if="registerDeadsController" @hidden="closeRegisterDeads" :evolution-specimen="1" />

		<!-- MOSTRAR DETALLE DE LA POSTURA POR LOTES -->
		<b-modal v-model="deadSpecimenDetail" size="xl" :title="titleDeadModal" hide-footer>
			<DeadCintilloPataDetail @refreshTableZero="refreshTableZero" :posturaId="selectedPosturaId" />
		</b-modal>

		<!-- INICIO DE MOSTRAR DETALLE DE LA POSTURA -->
		<b-modal
			id="detailPosturaModal"
			title="Detalle"
			v-model="modalDetailPostura"
			hide-footer
			size="lg"
			scrollable
			centered
		>
			<postura-detail :postura="{ id: selectedPosturaId }"></postura-detail>
		</b-modal>
		<!-- FIN DE MOSTRAR DETALLE DE LA POSTURA -->

		<b-modal
			v-if="modalViewByPostura.visible"
			v-model="modalViewByPostura.visible"
			:title="modalViewByPostura.title"
			hide-footer
			size="xmd"
		>
			<LotesList :postura_id="modalViewByPostura.postura_id" />
		</b-modal>
	</div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import RegisterDeads from "./modals/RegisterDeads.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeadSpecimenEvidence from "./modals/DeadSpecimenEvidence";
import DeadCintilloPataDetail from "./modals/DeadCintilloPataDetail.vue";
import PosturaDetail from "../encaste/posturas/PosturaDetail.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import axios from "@/axios";
import LotesList from "../encaste/lotes/LotesList.vue";
import CombChickenlegsCintillos from "../encaste/lotes/components/CombChickenlegsCintillos.vue";

export default {
	name: "DeadCintilloPataGrid",
	directives: {
		Ripple,
	},
	components: {
		AppCollapse,
		AppCollapseItem,
		RegisterDeads,
		DeadCintilloPataDetail,
		DeadSpecimenEvidence,
		PosturaDetail,
		SpecimenPlate,
		LotesList,
		CombChickenlegsCintillos,
	},
	data() {
		return {
			sortBy: "sm.plate",
			sortDesc: false,
			arrayColumns: [
				{
					key: "sm.plate",
					label: "Madrilla",
					class: "text-center",
					sortable: true,
				},
				{
					key: "sp.alias",
					label: "Padrillo",
					class: "text-center",
					sortable: true,
				},
				{
					key: "code",
					label: "Postura",
					class: "text-center",
				},
				{
					key: "quantity",
					label: "Cantidad",
					class: "text-center",
					sortable: true,
				},
				{
					key: "actions",
					label: "Acciones",
					class: "text-center ",
				},
			],
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			perPageOptions: [10, 25, 50, 100],
			chickenleg: null,
			loading: false,
			deadSpecimenDetail: false,
			show: false,
			modalDetailPostura: false,
			selectedPosturaId: null,
			registerDeadsController: false,
			modalViewByPostura: {
				title: "Detalle de postura",
				visible: false,
				postura_id: null,
			},
			modalViewDetails: {
				title: "Detalle de postura",
				visible: false,
				postura_id: null,
			},
			titleDeadModal: null,
		};
	},
	methods: {
		openModalPosturaDetail(postura) {
			this.selectedPosturaId = postura;
			this.modalDetailPostura = true;
		},
		onChangeFilter() {
			this.$refs.refDeadList.refresh();
		},
		async myProvider(ctx) {
			const promise = axios.post(`${ctx.apiUrl}?page=${ctx.currentPage}`, {
				orderby: ctx.sortDesc == 0 ? "asc" : "desc",
				order: ctx.sortBy == "" ? "sm.plate" : ctx.sortBy,
				campo: ctx.filter,
				perpage: ctx.perPage,
				type_specimen: 1,
			});
			return await promise.then(({ data }) => {
				const items = data.data.map((it) => {
					let cintillos = {
						...it.cintillos,
						chickenleg_wich_leg:
							it.cintillos &&
							(it.cintillos.wich_leg == "IZQUIERDA" ? 1 : it.cintillos.wich_leg == "DERECHA" ? 2 : 3),
					};
					return {
						...it,
						cintillos: it.cintillos ? cintillos : null,
					};
				});

				this.start_page = data.from;
				this.current_page = data.current_page;
				this.perpage = data.per_page;
				this.next_page = this.start_page + 1;
				this.end_page = data.last_page;
				this.total_data = data.total;
				this.to_page = data.to;
				return items || [];
			});
			
		},
		resetSearch() {
			this.search_input = "";
			this.$refs.refDeadList.refresh();
		},
		openRegisterDeads() {
			this.registerDeadsController = true;
		},
		closeRegisterDeads() {
			this.registerDeadsController = false;
			this.resetSearch();
		},
		submitButton() {
			this.$refs.deadPage.upload();
		},
		openModalDetail(dead) {
			this.isPreloading();
			this.selectedPosturaId = dead.item.id;
			this.deadSpecimenDetail = true;
			this.titleDeadModal = `Muertos de ${dead.item.code} en cintillo pata`;
		},
		showToast(variant, position, title, icon, text) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			);
		},
		deleteDeadSpecimen(dead) {
			this.$swal({
				title: "Estas seguro?",
				text: "¡No podrás revertir esto!",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Confirmar",
				customClass: {
					cancelButton: "btn btn-outline-danger ml-1",
					confirmButton: "btn btn-primary",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios
						.post("/api/deads/delete", {
							deleted_by: this.currentUser.user_id,
							dead_specimen_id: dead.id,
							cintillo_pata: dead.cintillo_pata,
							cintillo_ala: dead.cintillo_ala,
							quantity: dead.quantity,
							plate: dead.plate,
							type_specimen: dead.type_specimen_number,
							postura_id: dead.postura_id,
							lote_id: dead.lote_id,
						})
						.then((res) => {
							this.showToast("success", "top-right", "Felicidades", "CheckIcon", "Muerte eliminada!");
							this.$refs.refDeadList.refresh();
						});
				}
			});
		},
		openModal(data) {
			console.log(data);
			this.modalViewByPostura.visible = true;
			this.modalViewByPostura.title = `${data.code}`;
			this.modalViewByPostura.postura_id = data.id;
		},
		refreshTableZero() {
			this.$refs.refDeadList.refresh();
			this.deadSpecimenDetail = false;
		}
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
</style>
