<template>
	<div>
		<div>
			<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '80vh' }">
				<b-thead>
					<b-tr>
						<b-th v-for="(title, key) in headTable" :key="title.key">
							<p class="mb-0 text-center">{{ title.title }}</p>
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-if="chickens.length > 0" class="row-table" v-for="(item, index) in chickens" :key="index">
						<b-td class="text-center">
							<div>
								<strong>{{ item.cintillo_ala }}</strong>
								<br />
								<small>{{ item.lote_code }}</small>
							</div>
						</b-td>

						<b-td class="text-center">
							<div>
								<!-- <p class="mb-0 badge" :class="[item.status_id == 3 ? 'badge-danger' : 'badge-info']">
									{{ item.status_id == 3 ? "Muerto" : "Depurado" }}
								</p> -->
								<div class="d-flex align-items-center justify-content-center pr-1 pl-1 mt-50">
									<div
										@click="item.status_id = item.status_id == 3 ? 4 : 3"
										class="container-switch mr-50"
									>
										<div class="barra" :class="[item.status_id == 3 ? '' : 'active']"></div>
										<div class="barra-text">
											<div class="w-50 d-flex justify-content-center align-items-center">
												<p
													class="text-uppercase mb-0"
													:class="[item.status_id == 3 ? 'text-light' : 'text-primary']"
												>
													Muerto
												</p>
											</div>
											<div class="w-50 d-flex justify-content-center align-items-center">
												<p
													class="text-uppercase mb-0"
													:class="[item.status_id == 3 ? 'text-primary' : 'text-light']"
												>
													Depurado
												</p>
											</div>
										</div>
									</div>

									<FeatherIcon
										@click="resurrect(item)"
										v-b-tooltip.hover
										:title="item.l_status != 'FINALIZADO' ? 'Restaurar' : 'Restaurar bloqueado'"
										class="cursor-pointer text-danger"
										icon="PlusSquareIcon"
										size="18"
									/>
								</div>
							</div>

							<!-- <p class="mb-0 badge" :class="[item.status_id == 3 ? 'badge-danger' : 'badge-info']">
								{{ item.status_id == 3 ? "Muerto" : "Depurado" }}
							</p>
							<FeatherIcon
								@click="resurrect(item)"
								v-b-tooltip.hover
								title="Restaurar"
								class="cursor-pointer ml-50 text-warning"
								icon="AlertOctagonIcon"
								size="18"
							/> -->
						</b-td>

						<b-td class="text-center">
							<v-select
								v-model="item.r_id"
								:options="reasonsArray"
								:clearable="false"
								label="reason"
								:reduce="(option) => option.id"
								placeholder="Elegir razon de muerte"
								v-show="item.status_id == 3"
								style="width: 280px"
							>
								<template v-slot:option="option">
									<div class="d-flex justify-content-between align-items-center">
										<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`"
											>{{ option.reason }}
										</span>
										<span v-if="option.parent_id"> Sub-motivo de {{ option.parent_reason }} </span>
									</div>
								</template>
							</v-select>
						</b-td>

						<b-td class="text-center">
							<!-- placeholder="Agregar observacion" -->
							<b-form-textarea v-model="item.observation" rows="3" max-rows="6"></b-form-textarea>
						</b-td>

						<b-td class="text-center">
							<template v-if="item.created_by">
								<p class="mb-0">{{ item.created_by }}</p>
								<span class="font-small-4">{{ item.created_at | myGlobalDayShort }}</span>
							</template>
							<template v-else>
								<p class="mb-0">Sin usuario</p>
							</template>
						</b-td>

						<b-td class="text-center">
							<div class="grid-images-details-ca">
								<div class="image-item" v-for="(image, key) in item.evids">
									<img class="" :src="image.image" alt="" />
									<FeatherIcon
										@click="removeImage(image)"
										icon="XCircleIcon"
										size="24"
										class="icon-delete cursor-pointer"
									/>
								</div>
								<div class="image-not-found">
									<p class="mb-0">Subir imagen</p>
									<input
										@input="addImageArrayChick(index)"
										class="input"
										type="file"
										ref="fileInput"
									/>
								</div>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="chickens.length == 0">
						<b-td colspan="7" class="text-center">
							<p class="mb-0">Sin cintillos encontrados</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
		<div class="mt-1 d-flex justify-content-end" v-if="chickens.length > 0">
			<button class="btn btn-primary" @click="saveChanges">Guardar cambios</button>
		</div>
	</div>
</template>

<script>
import deadsService from "@/services/deads.service";
import reasonsService from "@/services/reasons.service";
import { setEvidencesBase64 } from "@/helpers/functions";
import heic2any from "heic2any";

export default {
	name: "DeadCintilloAlaDetail",
	props: {
		posturaId: Number,
	},
	data() {
		return {
			headTable: [
				{ key: "correlative", title: "# CINTILLO" },
				{ key: "status", title: "Estado" },
				{ key: "reason", title: "Razón" },
				{ key: "observation", title: "Observación" },
				{ key: "created_by", title: "Creado por" },
				{ key: "evidencias", title: "Evidencias" },
			],
			chickens: [],
			reasonsArray: [],
		};
	},
	async mounted() {
		this.isPreloading();
		await this.getReasons();
		await this.init();
	},
	methods: {
		async init() {
			this.isPreloading();
			const {
				data: { message, deads },
			} = await deadsService.getDetailDeadByPostura({
				type: 2,
				posture_id: this.posturaId,
			});
			// console.log('object', resp);
			this.chickens = deads.map((it) => {
				return {
					...it,
					evids: it.evids.filter((e) => e.id !== null),
					evids_delete: [],
					customFormData: [],
				};
			});
			this.isPreloading(false);
		},
		async getReasons() {
			const resp = await reasonsService.getByModule();
			this.reasonsArray = resp;
		},
		addImageArrayChick(key) {
			this.$refs.fileInput[key].click();
			let input = this.$refs.fileInput[key];
			let file = input.files;
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1];
			if (typeOfFile == "png" || typeOfFile == "jpg" || typeOfFile == "jpeg") {
				// this.setImage(file, "image");
				this.chickens = this.chickens.map((it, index) => {
					if (index == key) {
						return {
							...it,
							evids: [
								...it.evids,
								{
									id: null,
									image: URL.createObjectURL(file[0]),
									thumb: URL.createObjectURL(file[0]),
									file: file[0],
								},
							],
						};
					}
					return it;
				});
			} else if (typeOfFile == "heif" || typeOfFile == "heic") {
				this.isPreloading();
				this.showToast(
					"warning",
					"top-right",
					"Cargando imagen, espere un momento",
					"CheckIcon",
					"El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
				);
				heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
					const url = URL.createObjectURL(newImage);
					let newFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					});
					this.chickens = this.chickens.map((it, index) => {
						if (index == key) {
							return {
								...it,
								evids: [
									...it.evids,
									{
										id: null,
										image: url,
										thumb: url,
										file: newFile,
									},
								],
							};
						}
						return it;
					});

					this.isPreloading(false);
				});
				this.$refs.fileInput.value = null;
			}
		},
		async saveChanges() {
			let arrayToDead = this.chickens.filter((ck) => ck.status_id === 3 && ck.r_id == null);

			if (arrayToDead.length > 0) {
				this.showToast(
					"danger",
					"top-right",
					"Error",
					"AlertCircleIcon",
					"Seleccione una observación por muerto"
				);
				return;
			}

			this.isPreloading();

			this.chickens = await setEvidencesBase64(
				this.chickens.map((it) => {
					return {
						...it,
						evidences: it.evids.filter((e) => e.id === null).map((it) => it.file),
					};
				})
			);

			const deads = this.chickens.map((it) => {
				return {
					type: 2,
					id: null,
					reason_id: it.status_id == 3 ? it.r_id : null,
					lote_id: it.lote_id,
					elo_id: it.chick_tk_id,
					error: null,
					observation: it.observation,
					status_id: it.status_id,
					chick_id: it.ck_id,
					chick_tk_id: it.cts_id,
					evids_to_add: it.evids.filter((image) => !image.id),
					evids_to_delete: it.evids_delete.map((it) => it.id),
					evidences: it.customFormData,
				};
			});

			let imagesArray = [];

			deads.forEach((it) => {
				const images = it.evidences.map((df) => {
					return {
						...df,
						chick_id: it.chick_id,
					};
				});
				imagesArray = [...imagesArray, ...images];
			});

			// console.log("DEADS", deads, imagesArray);

			const {
				data: { message },
			} = await deadsService.updateDeads({
				deads,
				evidences: imagesArray,
				type: 2
			});
			this.isPreloading(false);
			this.showToast("success", "top-right", "Muertos", "AlertCircleIcon", message);
			await this.init();
		},
		removeImage(item) {
			this.chickens = this.chickens.map((it) => {
				return {
					...it,
					evids: it.evids.filter((e) => e.id != item.id),
					evids_delete: [...it.evids_delete, ...it.evids.filter((e) => e.id == item.id)],
				};
			});
			this.showToast("success", "top-right", "Muertos", "AlertCircleIcon", "Imagen removida correctamente.");
		},
		async resurrect(item) {
			// Alerta de confirmar
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			// Continuar peticion
			this.isPreloading();
			const {
				data: { message },
			} = await deadsService.toReviveCACP({
				id: item.ck_id,
				type: 2,
				chick_tk_id: item.cts_id,
			});
			this.chickens = this.chickens.filter((it) => it.ck_id != item.ck_id);
			if (this.chickens.length == 0) {
				this.$emit("refreshTableZero");
			}
			this.isPreloading(false);
			this.showToast("success", "top-right", "Muertos", "AlertCircleIcon", message);
		},
	},
};
</script>

<style lang="scss" scoped>
.grid-images-details-ca {
	display: flex;
	// background: #7367f0;
	justify-content: center;
	overflow-x: auto;
	// padding-left: 4rem;
	width: 500px;
	// width: 400px;
	// min-width: 400px;
	// flex-wrap: wrap;
	// overflow-x: ;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	padding: 0.5rem;
	.image-item {
		position: relative;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		margin: 0 5px;
		margin-bottom: 0.8rem;
		.icon-delete {
			position: absolute;
			top: -4px;
			right: -4px;
			color: red;
			background: #fff;
			border-radius: 50%;
		}
	}
	img {
		width: 100px !important;
		height: 100px !important;
		object-fit: cover;
		// margin: 0 5px;
		border: 2px solid #7367f0;
		border-radius: 4px;
	}
	.image-not-found {
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		width: 100px !important;
		height: 100px !important;
		margin: 0 5px;
		border: 2px dashed gray;
		border-radius: 4px;
		padding: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: relative;
		p {
			font-size: 13px;
			line-height: 1.2;
		}
		.input {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			cursor: pointer;
		}
	}
}

.grid-images-details-ca::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
	height: 4px;
}

.grid-images-details-ca::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}
.container-switch {
	background: #e4e3e3;
	width: 140px;
	height: 40px;
	border-radius: 25px;
	position: relative;
	cursor: pointer;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	.barra {
		position: absolute;
		top: 3px;
		left: 3px !important;
		width: 67px;
		height: 34px;
		background: #7367f0;
		border-radius: 34px;
		transition: all 0.3s ease;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	}
	.barra-text {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// padding: 0 .5rem;
		p {
			font-size: 10px;
			font-weight: bolder;
		}
	}
	.active {
		left: calc(67px + 3px) !important;
	}
}
</style>
